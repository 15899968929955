@import "App";

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 300 11px/1.4 'Rubik';
  background: $bg-color;
  overflow: hidden;
  display: block;
}
