@import './App.scss';

.about {
    position: relative;
    padding-top: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
}

.blur {
    position: absolute;
    box-shadow: 10px 80px 5000px 60px #9d60bc;
    z-index: -100;
}

.about .content{
    font-family: 'Poppins';
    margin: auto;
    padding: 5rem 2rem;
    display: block;
    width: 80%;
}

.about .content h1 {
    font-size: 6rem;
    color: $secondary-color;
    font-weight: 700;
}

.about .content p {
    color: $secondary-color;
    font-size: 2rem;
}

.about .content h1 span {
    color: $primary-color;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px;
    transition: -webkit-text-fill-color 0.4s ease;
}

.about .image {
    position: relative;
    margin: auto;
}

.about .image img {
    object-fit: cover;
    display: flex;
    height: 30vw;
    width: 30vw;
    max-width: 420px;
    max-height: 420px;
    border-radius: 50%;
    box-shadow: $secondary-color 0px 0px;
    transition: box-shadow 0.4s ease;
}

.about .image img:hover {
    box-shadow: $primary-color 20px 20px;
}