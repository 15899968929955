$primary-color: #5da064;
$secondary-color: rgb(232, 219, 219);
$bg-color: #1b252f;

@import 'animate.css';
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;500;600;700;800;900&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Rubik:wght@300;400;500;600;700;800&display=swap');


html {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
}
